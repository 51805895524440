//
// Body, html
//---------------------------------------------------
html,body {
    overflow-x: hidden;
}
body {
    font-family: "source-sans-pro";
    font-weight: normal;
    font-style: normal;
}

//
// Typography
//---------------------------------------------------
h2 {
    font-weight: 700;
    font-size: 40px;
    text-transform: uppercase;
    text-align: center;
    color: @redColor;
    margin-bottom: 55px;
}

h3 {
    font-weight: 700;
    font-size: 35px;
    color: @blueColor;
    margin-top: 0;
    margin-bottom: 17px;
}

p {
    font-size: 18px;
    color: #656565;
}

a {
    color: @redColor;
    &:hover {
        color: @redColor;
        text-decoration: underline;
    }
}
//
// Essential classes
//---------------------------------------------------
.pb-0 {
    padding-bottom: 0 !important;
}
.article-padding {
    padding: 9px;
    @media (min-width: @screen-md-min) {
        padding: 0 15px;
    }
}

.clear:after {
    clear: both;
    content: "";
    display: table;
}

.no-padding {
    padding: 0 !important;
}

.img-thumbnail {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    padding: 7px;
    margin: 15px 0;
}

.affix {
    position: fixed !important;
    top: 0;
    width: 100%;
}


//
// Header
//---------------------------------------------------
.header {
    background: url(../images/header_bg.png) no-repeat center top;
    min-height: 214px;   
    position: fixed;
    z-index: 999;
    padding-top: 3px;
    color: @blueColor;
    @media (max-width: @screen-md-max) {
        padding-top: 20px;
    }
    &-wrapper {
        display: inline-block;
        margin: 0 auto;
        @media (max-width: @screen-md-max) {
            float: left;
        }
    }
    &__image {
        display: inline-block;
        margin-right: 10px;
        vertical-align: baseline;
    }
    &__title {
        display: none;
        font-weight: 700;
        font-size: 26px;
        margin-top: 8px;
        margin-bottom: -6px;
        text-transform: uppercase;

        // Large devices and Medium device
        @media (min-width: @screen-md-min) {
                display: block;
        } 
    }
    &__text {
        &-wrapper {
            display: inline-block;
            text-align: left;
        }
        display: none;
        font-weight: 700;
        font-size: 12px; 

        &:not(:last-child):after, &:not(:last-child)::after {
            content: "";
            border-right: 1px solid @blueColor;
            height: 12px;
            margin: 0 10px -3px 10px;
            display: inline-block;
        }            

        a {
            color: @blueColor;
        }

        // Large devices and Medium device
        @media (min-width: @screen-md-min) {
                display: inline-block;
        } 
    }
}

//
// Navbar
//---------------------------------------------------
.collapsing {
    position: initial;
}

.navbar {
    margin-bottom: 0;
    background: transparent;
    border: 0;

    &-wrapper {
        // Extra small devices
        @media (max-width: @screen-md-max) { 
            margin-left: -25px;
            margin-right: -25px;
        }
    }
    &-default {
        .navbar-toggle {
            border: 1px solid #9C9C9C;
            background-color: @whiteColor;
            .icon-bar {
                background-color: #656565;
            }

            &:hover, &:focus, &.active {          
                border: 1px solid #656565;
                background-color: @whiteColor;
                .icon-bar {
                    background-color: #2d2d2d;
                }
            }  
        } 

        .navbar-collapse {
            border: 0;
            overflow: hidden;
            position: absolute;
            left: 0;
            right: 0;
        }  

        .navbar-nav {
            // Extra small devices
            @media (max-width: @screen-md-max) { 
                background: @whiteColor;
                border: 1px solid #c9c9c9;
                border-width: 1px 0;
                text-align: center;
                padding-bottom: 19px;
                margin-bottom: 25px;
                margin-top: 19px;
                margin: 19px -5px 25px -5px;
                -webkit-box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,0.75);
                -moz-box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,0.75);
                box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,0.75);
            }

            li {
                margin-top: 3px;

                    // Large devices and Medium device
                    @media (min-width: @screen-lg-min) {
                        + li {
                            &:before, &::before {
                                content: "";
                                border-left: 1px solid @blueColor;
                                height: 21px;
                                margin: 0 6px -5px 6px;
                                display: inline-block;
                            }   
                        }   
                    }      
                a {
                    font-weight: 600;
                    font-size: 18px;
                    color: @blueColor;   
                    background: none;
                    padding: 0;
                    min-height: 40px;
                    line-height: 40px;

                    // Large devices and Medium device
                    @media (min-width: @screen-lg-min) {
                        display: inline-block;
                    }

                    &:hover, &:focus, &.active {
                        color: @blueColor;
                        text-decoration: underline;
                        background: none;
                    }  
                    img {
                        margin-top: -3px;
                    }  
                }  
                &.active a {
                    &,&:hover, &:focus {
                        background-color: transparent;
                        color: @blueColor;
                        text-decoration: underline;
                    }
                    &:focus {
                        text-decoration: none;
                    }
                }

                @media (max-width: @screen-md-max) {
                    &:not(:last-child) a {
                        padding-bottom: 5px;
                        border-bottom: 1px solid #e7e7e7;
                    }
                }                     
            }
        }   
    }
}


//
// BEMS
//---------------------------------------------------
.section {
    width: 100%;

    &--slider {
        min-height: 600px;
        margin-top: 110px;
        position: relative;
        z-index: 0;
    }
    &--bg {
        position: relative;
        margin-top: 60px;
        padding-bottom: 60px;
        background-color: #f2f1f1;
        &:before, &::before {
            content: "";
            position: absolute;
            display: block;
            width: 100%;
            height: 60px;
            background: transparent url(../images/section_bg.png) no-repeat center top;     
            margin-top: -60px;
        }
        &:after, &::after {
            content: "";
            position: absolute;
            display: block;
            width: 100%;
            height: 60px;
            background: transparent url(../images/slider_bg.png) no-repeat center top;     
        }
    }
    &--first {
        position: relative;
        background: transparent url(../images/slider_bg.png) no-repeat center top;        
        margin-top: -101px;
        padding-top: 104px;   
    }
    &--grey {
        margin-top: 60px;
        .container {
            position: relative;
            background-color: @greyColor;    
            padding-bottom: 20px;
            &:before, &::before {
                content: "";
                position: absolute;
                display: block;
                width: ~"calc(100% - 30px)";
                height: 0;
                border-bottom: 1px solid #cdcdcd;
                margin-top: -60px;
            }
        }

        h3 {
            text-align: center;
            border-bottom: 1px solid #e4e4e4;
            padding: 25px 0;
            margin-bottom: 30px;
        }

        ul {
            padding: 0;
            vertical-align: top;

            > li {
                font-weight: 400;
                font-size: 25px;
                display: block;
                padding: 0 30px 0px 40px;
                margin-bottom: 6px;
                position:relative;
                color: #656565;
                text-decoration: none;
                
                &:before, &::before {
                    transform: translate(0, -20%);
                    -webkit-transform: translate(0, -20%);
                    -moz-transform: translate(0, -20%);
                    -o-transform: translate(0, -20%);
                    -ms-transform: translate(0, -20%);
                    content: "";
                    width: 10px;
                    height: 10px;
                    margin-right: 15px;
                    display: inline-block;
                    background-color: @redColor;              
                }
                a {
                    color: #656565;
                    &:hover {
                        color: #656565;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

//
// Owl carousel fix
//---------------------------------------------------
.owl-carousel {
    z-index: 1;
}

.owl-wrapper-outer {
    z-index: 1;
}

.owl-stage {
    z-index: 1;
}

.owl-stage-outer {
    z-index: 2;
}

.owl-item {
    z-index: 1;
}

//
// Slider
//--
.wrapper-slider {
  position: relative;
}

.slide {
    background-image: url(../img/1920x600.png);
    background-position: center;
    height: 600px;
    text-align: center;

    h1 {
        font-size: 65px;
        color: #fff;
        border: 0;
    }

    p {
        font-size: 20px;
        color: #fff;
        margin-bottom: 30px;
        max-width: 680px;
        margin-left: auto;
        margin-right: auto;
    }
}

.owl-nav {
    position: absolute;
    z-index: 2;
    top: 237px;
    left: 36px;
    right: 36px;
    height: 0;
    overflow: visible;

    .owl-prev, .owl-next {
        width: 28px;
        height: 116px;
        font-size: 0;
        background-image: url(../images/owl-nav.png);
    }

    .owl-prev {
        background-position: 0 0;
        float: left;
    }

    .owl-next {
        background-position: -28px 0;
        float: right;
    }
}

.slide-box {
    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;
    padding: 15px 15px;
    width: 100%;
    display: inline-block;
    text-shadow: 0px 2px 8px rgba(0, 0, 0, 1);
    text-transform: uppercase;
    text-align: center;
    line-height: 70px;

    // Small devices and Extra small devices  
    @media (min-width: @screen-sm-min) {
        margin-top: 180px;
        text-align: left;
    }

    img.price-img {
        margin: 0 auto;
        width: auto;

        // Small devices and Extra small devices
        @media (min-width: @screen-sm-min) {
            margin: -195px -40px 0 0;
            float: right;
            display: inline-block;
        }
    }
}

.slide-big {
    display: inline-block;
    font-weight: 700;
    color: @whiteColor;
    font-size: 34px;

    // Small devices and Extra small devices
    @media (min-width: @screen-xs-min) {
        font-size: 48px;
    }

    // Small devices and Extra small devices
    @media (min-width: @screen-sm-min) {
        text-align: left;
        width: 400px;
        font-size: 60px;
    }
}

.slide-small {
    display: block;
    color: #fff;
    font-size: 30px;
    font-weight: bold;
}

// Extra small devices
@media (max-width: @screen-xs-max) {
    .slide {
        padding-top: 0;
    }
}

// Small devices and Extra small devices
@media (max-width: @screen-sm-max) {
    .owl-nav {
        display: none;
    }
}

//
// Articles
//--
.article-item {
    margin-bottom: 60px;

    &__text {
        text-align: center;
        font-weight: 400;
        line-height: 160%;
        font-size: 18px;

        // Medium devices
        @media (min-width: @screen-md-min) {
            margin-top: 0px;
            text-align: left;
        }

        p:not(:last-child) {
            padding-bottom: 15px;
        }
    }

    &__image {
        font-size: 0;

        @media (min-width: @screen-sm-min) {
            picture:nth-child(3n-1) img {
                margin: 0 9px 9px 9px;
            }
        }

        img { 
            margin: 15px auto;
            -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);
            -moz-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);

            @media (min-width: @screen-sm-min) {
                display: inline-block;
                margin: 0 auto 9px auto;
            }                
        }

    }
}


//
// Footer
//---------------------------------------------------
.footer {
    margin-top: 60px;
    font-weight: 400;
    font-size: 18px;    
    color: #656565;
        border-top: 3px solid @blueColor;
        background-color: @greyColor;   
        padding-top: 30px;   
    .container {

    }
    
    [class*="col-sm-"] {
        padding: 0;
    }

    &--contact {
        text-align: center;
        // small devices
        @media (min-width: @screen-sm-min) { 
            text-align: left;
        }

        span {
            font-size: 25px;
            font-weight: 700;
            color: @redColor;
        }   

        ul {
            margin-top: 10px;
            padding: 0;
            vertical-align: top;

            > li {
                display: block;
                padding: 0 0 0 30px;
                margin-bottom: 10px;
                position:relative;
                min-height:25px;

                &:before, &::before {
                    font: normal normal normal 14px/1 FontAwesome;
                    font-size: inherit;
                    text-rendering: auto;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    top: 50%;
                    transform: translate(0, -50%);
                    -webkit-transform: translate(0, -50%);
                    -moz-transform: translate(0, -50%);
                    -o-transform: translate(0, -50%);
                    -ms-transform: translate(0, -50%);
                    font-size: 22px;
                    content: "";
                    position:absolute;
                    margin-left: -30px;      
                    color: @blueColor;               
                }

                &.map-marker {&:before, &::before{content: "\f041";}}
                &.earphone {&:before, &::before{content: "\f095";margin-top: -1px;}}
                &.envelope {&:before, &::before{content: "\f003";margin-top: -3px;}}
            }
        } 

        a {
            color: #656565;
            text-decoration: underline;

            &:hover, &:focus, &.active {
                color: #656565;
                text-decoration: none;
            }  
        }    
    }

    &--social {
        text-align: center;
        margin: 45px auto 0 auto;

        span {
            display: block;     
            font-size: 15px;
            font-weight: 400;
            color: #656565;           
        }
    }

    &__text {
        margin-top: 15px;
        text-align: center;

        // small devices
        @media (min-width: @screen-sm-min) { 
            margin-top: 54px;
            float: left;
            width: ~"calc(100% - 160px)";
        }

        // Large devices and Medium device
        @media (min-width: @screen-md-min) {
            margin-top: 48px;
            width: ~"calc(100% - 120px)";
            text-align: right;
        }

        a {
            font-weight: 700;
            font-size: 25px;
            color: @redColor;
            text-decoration: none;

            &:hover, &:focus, &.active {
                color: @redColor;
                text-decoration: underline;
            }  
        }        
    }

    &__image {
        margin: 30px auto;

        // small devices
        @media (min-width: @screen-sm-min) { 
            margin: 30px;
            float: right;
        }

        @media (min-width: @screen-md-min) {
            margin: 30px 0 0 0;
        }
    }

    &__copy {
        border-top: 1px solid #e4e4e4;
        padding: 10px 0;

        ul {
            padding: 0;

            li {
                display: block;

                a {
                    color: #656565;
                    text-decoration: none;

                    &:hover, &:focus, &.active {
                        color: #656565;
                        text-decoration: underline;
                    }  
                } 

                // small devices
                @media (min-width: @screen-sm-min) {  
                    display: inline-block;

                    &:first-child:after, &:first-child::after {
                        content: "";
                        border-right: 1px solid #656565;
                        height: 16px;
                        margin: 0 6px -3px 6px;
                        display: inline-block;
                    } 
                }                 
            }
        }
    }
}

.cat {
    margin-bottom: 45px;
    + .cat {
        padding-top: 45px;
        border-top: 1px solid #cdcdcd;
    }

    .row {
        margin: 0 -30px;
    }

    p {
        margin-bottom: 15px;
    }
}

.btn-large {
    display: block;
    background-color: @redColor;
    color: @whiteColor;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    padding: 27px 15px;
    &:hover {
        background-color: @blueColor;
        color: @whiteColor;
        text-decoration: none;
    }
    .big {
        font-size: 31px;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 14px;
    }
    span {
        display: block;
        margin-bottom: 5px;
    }
    .link {
        font-size: 18px;
        font-weight: 700;
        display: block;
        margin-top: 10px;
        margin-bottom: 0;
    }
}